.m-panel.m-panel-measurebar {
  z-index: 99999;
}

.m-measurelength-container {
    top: 0px;
    left: 200px;
    order: 10;
}

.m-measurearea-container {
    top: 0px;
    left: 250px;
    order: 9;
}

.m-measureclear-container {
    top: 0px;
    left: 300px;
    order: 8;
}

.m-measure-tooltip {
    position: absolute;
    z-index: 990;
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    filter: alpha(opacity=0);
    opacity: 0;
    line-break: auto;
}

.m-areas>div.m-area>div.m-panel.m-panel-measurebar.opened>button.m-panel-btn.g-cartografia-flecha-izquierda {
    color: white !important;
}

/* .m-measure-tooltip.pointer */
.m-measure-tooltip.measure {
    position: relative;
    background: #b54d01;
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: .75;
    white-space: nowrap;
}

.m-measure-tooltip.measure {
    font-weight: bold;
}

.m-measure-tooltip.static {
    background-color: #337ceb;
    color: #fff;
    border: 1px solid white;
    font-weight: normal;
    opacity: 1;
}

.m-measure-tooltip.measure:before,
.m-measure-tooltip.static:before {
    border-top: 6px solid #b54d01;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.m-measure-tooltip.static:before {
    border-top-color: #337ceb;
}

#measurebar-area-btn,
#measurebar-length-btn {
  cursor: pointer;
}

/* Icons font */

@font-face {
    font-family: 'measurebar';
    src: url('../fonts/measurebar.eot?ax3k4p');
    src: url('../fonts/measurebar.eot?ax3k4p#iefix') format('embedded-opentype'),
        url('../fonts/measurebar.ttf?ax3k4p') format('truetype'),
        url('../fonts/measurebar.woff?ax3k4p') format('woff'),
        url('../fonts/measurebar.svg?ax3k4p#measurebar') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="measurebar-"],
[class*=" measurebar-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'measurebar' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.measurebar-medir-area:before {
    content: "\e1910";
}

.measurebar-medir-linea:before {
    content: "\e1911";
}

.measurebar-regla:before {
    content: "\e1912";
}

/* Mapea font override */

.m-areas>div.m-area>div.m-panel.m-panel-measurebar.collapsed>button.m-panel-btn,
#measurebar-area-btn,
#measurebar-length-btn {
    font-family: 'measurebar' !important;
}

/* Buttons */

div.m-panel.m-panel-measurebar.opened>button.m-panel-btn {
    background-color: #71a7d3 !important;
    color: white;
}

.m-areas>div.m-area>div.m-panel.m-panel-measurebar.opened>div.m-panel-controls>div.m-control>button[class^="measurebar-"],
.m-areas>div.m-area>div.m-panel.m-panel-measurebar.opened>div.m-panel-controls>div.m-control>button#measurebar-clear-btn {
    background-color: white;
    border: none;
    color: grey !important;
    font-size: 24px;
    height: 40px;
    width: 40px;
}

.m-areas>div.m-area>div.m-panel.m-panel-measurebar.opened>div.m-panel-controls>div.m-control.activated>button {
    color: #71a7d3 !important;
}

.m-areas>div.m-area>div.m-panel.m-panel-measurebar.opened>div.m-panel-controls>div.m-control>button {
    outline: none !important;
}

.m-areas>div.m-area.m-bottom>div.m-panel.m-panel-measurebar.opened {
    width: 160px;
}
